import React from 'react'
import AllStudExamResults from '../../Tables/Student/SAllExamResults'
const StudResults = () => {
  return (
    <div>
       <h3>
        Exam/<span>Student Exam Results</span>
      </h3>
      <AllStudExamResults/>
    </div>
  )
}

export default StudResults
